import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../services/api";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import { MdAddCircleOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Table";

const HEADER = [
  { title: "Email", key: "email" },
  { title: "Connexions", property: "w-32" },
  { title: "Onboarding OK", property: "w-36" },
  { title: "Échecs du vivant", property: "w-40" },
  { title: "Échecs biométrie", property: "w-40" },
  { title: "Échecs ShareID", property: "w-36" },
];

const List = () => {
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 10,
    sort: { created_at: -1 },
  });

  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await api.post(`/admin/user/search`, {
        skip: (filters.page - 1) * filters.pageSize,
        limit: filters.pageSize,
        sort: filters.sort,
      });
      if (res.ok) {
        setUsers(res.data);
        setTotal(res.total);
        fetchStats(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStats = async (usersData) => {
    try {
      const promises = usersData.map((user) =>
        api.post(`/stats/user`, { user_id: user._id, period: "ALL" }).then((res) => {
          if (res.ok) {
            return { ...user, stats: res.data };
          } else {
            return user;
          }
        }),
      );

      const results = await Promise.all(promises);

      setUsers((prev) => prev.map((u) => results.find((user) => user._id === u._id) || u));
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-end">
        <h1 className="text-2xl text-primary font-bold">{`Liste des utilisateurs (${total})`}</h1>
        <button onClick={() => setOpen(true)} className="primary-button text-sm">
          <MdAddCircleOutline className="mr-2 w-4 h-4" />
          Inviter un utilisateur
        </button>
      </div>

      <Table
        header={HEADER}
        sort={filters.sort}
        onSort={(key) => {
          setFilters({ ...filters, sort: !filters.sort[key] ? { [key]: 1 } : filters.sort[key] === 1 ? { [key]: -1 } : { created_at: -1 } });
        }}
        total={total}
        loading={loading}
        onSelectedData={setSelectedUsers}
        selectedData={selectedUsers}
        data={users}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune candidature n'a été trouvé</div>}>
        {loading
          ? [...Array(filters.pageSize)].map((_, index) => (
              <tr key={index} className={`text-center cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"}`}>
                <td className="py-4 px-3">
                  <div className="h-4 bg-gray-200 rounded w-full" />
                </td>
                {HEADER.map((_, index) => (
                  <td key={index} className="py-4 px-3">
                    <div className="h-6 bg-gray-200 rounded w-full" />
                  </td>
                ))}
              </tr>
            ))
          : users.map((item, index) => (
              <tr
                key={index}
                className={`text-center cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"} hover:bg-secondary`}
                onClick={() => navigate(`/user/${item._id}`)}>
                <td className="py-4 px-3 text-left">
                  <input
                    readOnly
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    checked={selectedUsers?.some((u) => u._id === item._id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (selectedUsers?.some((u) => u._id === item._id)) {
                        setSelectedUsers(selectedUsers?.filter((t) => t._id !== item._id));
                      } else {
                        setSelectedUsers([...selectedUsers, item]);
                      }
                    }}
                  />
                </td>
                <td className="py-4 px-3 text-left">{item.email}</td>
                <td className="py-4 px-3 text-left">{item.stats?.userLoginCount}</td>
                <td className="py-4 px-3 text-left">{item.stats?.onboardingDate?.slice(0, 10)}</td>
                <td className="py-4 px-3 text-left">{item.stats?.failedLivenessCount}</td>
                <td className="py-4 px-3 text-left">{item.stats?.failedBiometryCount}</td>
                <td className="py-4 px-3 text-left">{item.stats?.failedShareIdCount}</td>
              </tr>
            ))}
      </Table>
      <div className="w-full flex justify-center p-2 border-t rounded-b-lg bg-white">
        <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.pageSize)} />
      </div>
      {/* {open && <ModalNewProperty show={open} onClose={() => setOpen(false)} fetchData={fetchData} />} */}
    </div>
  );
};

export default List;
