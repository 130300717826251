import { useEffect, useState } from "react";
import Select from "../../components/Select";
import ReactApexChart from "react-apexcharts";

import GlobalStats from "./components/global";
import api from "../../services/api";
import Loader from "../../components/Loader";

const PERIOD_LABELS = {
  DAY: "Journalier",
  WEEK: "Hebdomadaire",
  MONTH: "Mensuel",
};

const Home = () => {
  const [period, setPeriod] = useState("MONTH");
  const [chartOptions, setChartOptions] = useState({
    chart: {
      events: {
        click: function (chart, w, e) {
          console.log(chart, w, e);
        },
      },
    },
    grid: {
      strokeDashArray: 8,
    },
    colors: ["#6E76AC"],
    plotOptions: {
      bar: {
        columnWidth: "35%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: ["#6E76AC"],
          fontSize: "12px",
        },
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchGraphStats = async () => {
    setLoading(true);
    try {
      const res = await api.post("/stats/graph", { period });
      if (res.ok) {
        console.log(res.data);
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: res.data.x,
          },
        }));
        setChartSeries([
          {
            data: res.data.y,
          },
        ]);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchGraphStats();
  }, [period]);

  return (
    <div className="p-8 flex flex-col gap-8">
      <GlobalStats />
      <div className="rounded-md bg-white border grid grid-cols-2 gap-6">
        <div className="col-span-2 flex justify-between items-center border-b px-4 py-4">
          <div className="flex gap-2">
            <h1 className="text-xl font-semibold">Analyse</h1>
            <div className="w-64">
              <Select options={["Du nombre de connexions"]} value={"Du nombre de connexions"} onChange={(value) => setDelay(value)} />
            </div>
          </div>
          <div className="w-40">
            <Select options={["DAY", "WEEK", "MONTH"]} value={period} onChange={(value) => setPeriod(value)} getLabel={(type) => PERIOD_LABELS[type]} />
          </div>
        </div>
        <div className="w-full col-span-2 min-h-[450px]">{loading ? <Loader /> : <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={450} />}</div>
      </div>
    </div>
  );
};

export default Home;
