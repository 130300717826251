import { useState } from "react";
import toast from "react-hot-toast";

import api from "../../services/api";
import { useNavigate } from "react-router-dom";

const New = () => {
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "admin",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const res = await api.post("/admin/user", values);
      if (res.ok) {
        toast.success("Utilisateur créé");
        navigate("/user");
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const handleGeneratePassword = () => {
    const password = Math.random().toString(36).slice(-8);
    setValues({ ...values, password });
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold">Nouvel utilisateur</h1>

      <div className="grid grid-cols-2 gap-4 mt-8">
        <div className="space-y-2">
          <label htmlFor="first_name" className="text-sm font-semibold">
            Prénom
          </label>
          <input type="text" id="first_name" className="input" value={values.first_name} onChange={(e) => setValues({ ...values, first_name: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="last_name" className="text-sm font-semibold">
            Nom
          </label>
          <input type="text" id="last_name" className="input" value={values.last_name} onChange={(e) => setValues({ ...values, last_name: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Email
          </label>
          <input type="email" id="email" className="input" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="password" className="text-sm font-semibold">
            Mot de passe
          </label>
          <div className="flex items-center space-x-2">
            <input type="text" id="password" className="input" value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })} />
            <button className="secondary-button" onClick={handleGeneratePassword}>
              Générer
            </button>
          </div>
        </div>

        <div className="space-y-2">
          <label htmlFor="role" className="text-sm font-semibold">
            Rôle
          </label>
          <select id="role" className="input" value={values.role} onChange={(e) => setValues({ ...values, role: e.target.value })}>
            <option value="admin">Administrateur</option>
            <option value="user">Utilisateur</option>
          </select>
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <button className="primary-button" onClick={handleSubmit}>
          {loading ? "Chargement..." : "Créer"}
        </button>
      </div>
    </div>
  );
};

export default New;
