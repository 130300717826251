import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Table = ({ header, sort, total, onSort, loading, noResultRender, children, onSelectedData, selectedData, data }) => {
  if (total === 0 && !loading) return noResultRender ? noResultRender : <div className="w-full">No data found</div>;
  return (
    <div className="border rounded-t-md mt-5 flex-1 overflow-x-auto no-scrollbar">
      <table className="min-w-max w-full rounded-md bg-white">
        <thead className={`text-left`}>
          <tr>
            <th className="py-5 px-2 font-semibold text-left w-14 border-b">
              <input
                type="checkbox"
                className="w-4 h-4 ml-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                checked={selectedData?.length === data.length && data.length !== 0}
                onChange={() => {
                  if (selectedData?.length === data.length) {
                    onSelectedData([]);
                  } else {
                    onSelectedData(data);
                  }
                }}
              />
            </th>
            {header.map((item, index) => (
              <th
                key={index}
                className={`py-5 px-3 font-semibold border-b ${item.key && "hover:bg-neutral-50 cursor-pointer"} ${item.property}`}
                onClick={() => {
                  item.key && onSort(item.key);
                }}>
                <div className="flex justify-between items-center gap-3">
                  <div>{item.title}</div>
                  {item.key && (
                    <div className="flex flex-col justify-center">
                      <IoIosArrowUp className={sort[item.key] === 1 ? "text-primary" : "text-secondary"} />
                      <IoIosArrowDown className={sort[item.key] === -1 ? "text-primary" : "text-secondary"} />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="relative">{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
