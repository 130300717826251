import { useEffect, useState } from "react";
import Select from "../../components/Select";
import ReactApexChart from "react-apexcharts";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import Loader from "../../components/Loader";

const PERIOD_LABELS = {
  DAY: "Journalier",
  WEEK: "Hebdomadaire",
  MONTH: "Mensuel",
};

const ANALYSIS_PERIOD_LABELS = {
  ONBOARDING: "Depuis l'onboarding",
  DAY: "Dernier jour",
  WEEK: "Dernière semaine",
  MONTH: "Dernier mois",
  TRIMESTER: "Dernier trimestre",
  YEAR: "Dernière année",
};

const View = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingGraph, setLoadingGraph] = useState(false);
  const [loadingAlalysis, setLoadingAlalysis] = useState(false);
  const [period, setPeriod] = useState("DAY");
  const [analysisPeriod, setAnalysisPeriod] = useState("ONBOARDING");

  const navigate = useNavigate();

  const [chartOptions, setChartOptions] = useState({
    chart: {
      events: {
        click: function (chart, w, e) {
          console.log(chart, w, e);
        },
      },
    },
    grid: {
      strokeDashArray: 8,
    },
    colors: ["#6E76AC"],
    plotOptions: {
      bar: {
        columnWidth: "35%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: ["#6E76AC"],
          fontSize: "12px",
        },
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/admin/user/${id}`);
        if (res.ok) {
          setData(res.data);
          fetchUserStats(res.data);
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const fetchUserStats = async () => {
    setLoadingAlalysis(true);
    try {
      const res = await api.post(`/stats/user`, { user_id: id, period: analysisPeriod });
      if (res.ok) {
        console.log(res.data);
        setData((prevData) => ({ ...prevData, stats: res.data }));
      }
    } catch (err) {
      console.error(err);
    }
    setLoadingAlalysis(false);
  };

  useEffect(() => {
    if (data.stats) fetchUserStats();
  }, [analysisPeriod]);

  const fetchGraphStats = async () => {
    setLoadingGraph(true);
    try {
      const res = await api.post("/stats/graph", { period, user_id: id });
      if (res.ok) {
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: res.data.x,
          },
        }));
        setChartSeries([
          {
            data: res.data.y,
          },
        ]);
      }
    } catch (err) {
      console.error(err);
    }
    setLoadingGraph(false);
  };

  useEffect(() => {
    if (!id) return;
    fetchGraphStats();
  }, [period]);

  const handleDeleteId = async () => {
    try {
      const confirm = window.confirm("Êtes-vous sûr de vouloir supprimer la pièce justificative ?");
      if (!confirm) return;
      const res = await api.put(`/admin/user/delete-id/${id}`);
      if (res.ok) {
        console.log("Id deleted");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const confirm = window.confirm("Êtes-vous sûr de vouloir supprimer l'utilisateur ?");
      if (!confirm) return;
      const res = await api.delete(`/admin/user/${id}`);
      if (res.ok) {
        navigate("/user");
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="p-8 flex flex-col gap-8">
      <div className="rounded-md bg-white border grid grid-cols-2 gap-6">
        <div className="col-span-2 flex justify-between items-center border-b px-4 py-4">
          <h1 className="text-xl font-semibold">Profil de l'utilisateur</h1>
        </div>
        <div className="col-span-2 flex justify-between border-b mx-4 pb-6">
          <div className="flex flex-col justify-start gap-2">
            <label className="text-2xl font-semibold">{data.email?.split("@")[0]}</label>
            <label className="font-light text-primary">Email : {data.email}</label>
          </div>
          <div className="flex gap-4">
            <a onClick={handleDeleteId} target="_blank" rel="noreferrer" className="red-button rounded-md cursor-pointer">
              Supprimer la pièce justi.
            </a>
            <a onClick={handleDeleteUser} target="_blank" rel="noreferrer" className="red-out-button rounded-md cursor-pointer">
              Supprimer l'utilisateur
            </a>
          </div>
        </div>
        <div className="mx-4 mb-4 flex flex-col gap-8 col-span-2">
          <div className="flex gap-2 items-center">
            <h1 className="text-xl font-semibold">Analyse : </h1>
            <div className="w-52">
              <Select
                options={["ONBOARDING", "DAY", "WEEK", "MONTH", "TRIMESTER", "YEAR"]}
                value={analysisPeriod}
                onChange={(value) => setAnalysisPeriod(value)}
                getLabel={(value) => ANALYSIS_PERIOD_LABELS[value]}
              />
            </div>
          </div>
          <div className="flex justify-start gap-5">
            <div className="flex flex-col px-4">
              <label className="text-sm font-light text-primary whitespace-nowrap">Onboarding finalisé</label>
              <label className="text-xl font-semibold mt-3">{data?.stats?.onboardingDate?.slice(0, 10)}</label>
            </div>
            <div className="flex flex-col border-l px-4">
              <label className="text-sm font-light text-primary whitespace-nowrap">Connexions</label>
              <label className="text-xl font-semibold mt-3">{loadingAlalysis ? "..." : data?.stats?.userLoginCount}</label>
            </div>
            <div className="flex flex-col border-l px-4">
              <label className="text-sm font-light text-primary whitespace-nowrap">Parcours A</label>
              <label className="text-xl font-semibold mt-3">{loadingAlalysis ? "..." : data?.stats?.ARouteCount}</label>
            </div>
            <div className="flex flex-col border-l px-4">
              <label className="text-sm font-light text-primary whitespace-nowrap">Parcours B</label>
              <label className="text-xl font-semibold mt-3">{loadingAlalysis ? "..." : data?.stats?.BRouteCount}</label>
            </div>
            <div className="flex flex-col border-l px-4">
              <label className="text-sm font-light text-primary whitespace-nowrap">Échecs du vivant</label>
              <label className="text-xl font-semibold mt-3">{loadingAlalysis ? "..." : data?.stats?.failedLivenessCount}</label>
            </div>
            <div className="flex flex-col border-l px-4">
              <label className="text-sm font-light text-primary whitespace-nowrap">Échecs biométrie</label>
              <label className="text-xl font-semibold mt-3">{loadingAlalysis ? "..." : data?.stats?.failedBiometryCount}</label>
            </div>
            <div className="flex flex-col border-l px-4">
              <label className="text-sm font-light text-primary whitespace-nowrap">Échecs Share ID</label>
              <label className="text-xl font-semibold mt-3">{loadingAlalysis ? "..." : data?.stats?.failedShareIdCount}</label>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
      <div className="rounded-md bg-white border grid grid-cols-2 gap-6">
        <div className="col-span-2 flex justify-between items-center border-b px-4 py-4">
          <div className="flex gap-2">
            <h1 className="text-xl font-semibold">Analyse des connexions</h1>
          </div>
          <div className="w-44">
            <Select options={["DAY", "WEEK", "MONTH"]} value={period} onChange={(value) => setPeriod(value)} getLabel={(type) => PERIOD_LABELS[type]} />
          </div>
        </div>
        <div className="w-full col-span-2 min-h-[450px]">{loadingGraph ? <Loader /> : <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={450} />}</div>
      </div>
    </div>
  );
};

export default View;
