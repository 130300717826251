import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Navbar from "./components/NavBar";
import Loader from "./components/Loader";
import Login from "./pages/auth/Login";
import api from "./services/api";
import useStore from "./services/store";
import TopBar from "./components/TopBar";

import Dashboard from "./pages/home";
import Parcours from "./pages/parcours";
import User from "./pages/user";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
          </Route>

          <Route element={<ProtectedLayout />}>
            <Route path="/home" element={<Dashboard />} />
            <Route path="/user/*" element={<User />} />
            <Route path="/parcours/*" element={<Parcours />} />
          </Route>
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </BrowserRouter>
      <Toaster position="top-center" />
    </>
  );
};

const AuthLayout = () => {
  const { user } = useStore();

  if (user) return <Navigate to="/" replace={true} />;
  return (
    <div className="flex justify-center w-screen h-screen pt-[15%]">
      <Outlet />
    </div>
  );
};

const ProtectedLayout = () => {
  const { user, setUser } = useStore();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    api
      .get("/admin/refresh")
      .then((res) => {
        if (res.ok) {
          setUser(res.user);
          api.setToken(res.token);
        } else {
          setUser(null);
          navigate("/login");
        }
        setLoading(false);
      })
      .catch(() => setUser(null));
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }
  if (!user) return <Navigate to="/login" replace={true} />;

  return (
    <>
      <div className="relative w-screen h-screen">
        <nav className="w-56 absolute left-0 top-0 border-r border-secondary">
          <Navbar />
        </nav>
        <main className="ml-56 h-full overflow-auto bg-gray-50">
          <div className="h-14 border-b border-secondary bg-white">
            <TopBar />
          </div>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default App;
