import { useEffect, useState } from "react";
import Select from "../../../components/Select";
import Loader from "../../../components/Loader";

import api from "../../../services/api";

const PERIOD_LABELS = {
  DAY: "Dernier jour",
  WEEK: "Dernière semaine",
  MONTH: "Dernier mois",
  TRIMESTER: "Dernier trimestre",
  YEAR: "Dernière année",
};

const GlobalStats = () => {
  const [period, setPeriod] = useState("YEAR");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchGlobalStat = async () => {
    setLoading(true);
    try {
      const res = await api.post("/stats/global", { period });
      if (res.ok) {
        console.log(res.data);
        setData(res.data);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchGlobalStat();
  }, [period]);

  return (
    <div className="rounded-md bg-white border grid grid-cols-2 gap-6">
      <div className="col-span-2 flex justify-between items-center border-b px-4 py-4">
        <h1 className="text-xl font-semibold">Vue globale</h1>
        <div className="w-40">
          <Select options={["DAY", "WEEK", "MONTH", "TRIMESTER", "YEAR"]} value={period} onChange={(value) => setPeriod(value)} getLabel={(type) => PERIOD_LABELS[type]} />
        </div>
      </div>
      <div className="grid grid-cols-6 gap-4 col-span-2 px-4">
        <div className="border rounded-md flex justify-start items-center p-6 col-span-3">
          <div className="flex flex-col justify-between gap-1">
            <label className="text-md">Nombre total de connexions</label>
            <label className="text-3xl font-semibold mt-3">{loading ? "..." : data.userLoginCount}</label>
          </div>
        </div>
        <div className="border rounded-md flex justify-start items-center col-span-3">
          <div className="flex flex-col justify-between p-6 gap-3">
            <label className="text-md">Onboardings finalisés</label>
            <label className="text-3xl font-semibold mt-3">{loading ? "..." : data.finalizedOnboardingCount}</label>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-6 gap-4 col-span-2 px-4 pb-6">
        <div className="border rounded-md flex justify-start items-center p-6 col-span-2">
          <div className="flex flex-col justify-between gap-3">
            <div className="flex flex-col">
              <label className="text-md">Nombre d'échecs de</label>
              <span className="font-semibold">reconnaissance du vivant</span>
            </div>
            <label className="text-3xl font-semibold mt-3">{loading ? "..." : data.failedLivenessCount}</label>
          </div>
        </div>
        <div className="border rounded-md flex justify-start items-center p-6 col-span-2">
          <div className="flex flex-col justify-between gap-3">
            <div className="flex flex-col">
              <label className="text-md">Nombre d'échecs de</label>
              <span className="font-semibold">reconnaissance biométrique</span>
            </div>
            <label className="text-3xl font-semibold mt-3">{loading ? "..." : data.failedBiometryCount}</label>
          </div>
        </div>
        <div className="border rounded-md flex justify-start items-center p-6 col-span-2">
          <div className="flex flex-col justify-between gap-3">
            <div className="flex flex-col">
              <label className="text-md">Nombre d'échecs d'authenticité</label>
              <span className="font-semibold">Share ID</span>
            </div>
            <label className="text-3xl font-semibold mt-3">{loading ? "..." : data.failedShareIdCount}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalStats;
