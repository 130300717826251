import React from "react";
import { BiLastPage, BiFirstPage } from "react-icons/bi";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

// Maximum number of pages to show in the paginator (min is 3)
const PAGINGATION_SIZE = 4;

const Pagination = ({ page, setPage, last }) => {
  const pages = [...Array(last || 1).keys()].map((i) => i + 1);

  return (
    <div className="w-full flex flex-row items-center justify-between gap-2">
      <div className="flex">
        <button className="transparent-button h-auto p-2" onClick={() => setPage(1)} disabled={page === 1}>
          <BiFirstPage />
        </button>
        <button className="mr-4 h-auto p-2 transparent-button" onClick={() => (page - 1 < 1 ? setPage(1) : setPage(page - 1))} disabled={page === 1}>
          <MdNavigateBefore className="mr-1" />
        </button>
      </div>
      <div className="w-72 flex items-center justify-center gap-2">
        {last > PAGINGATION_SIZE + 1 ? (
          page < PAGINGATION_SIZE ? (
            <>
              {pages.slice(0, PAGINGATION_SIZE).map((p) => (
                <button key={p} className={`h-auto p-2 ${p === page ? "primary-button" : "transparent-button"}`} onClick={() => setPage(p)}>
                  {p}
                </button>
              ))}
              <button className="transparent-button h-auto p-2">...</button>
              <button className="transparent-button h-auto p-2" onClick={() => setPage(last)}>
                {last}
              </button>
            </>
          ) : page > last - (PAGINGATION_SIZE - 1) ? (
            <>
              <button className="transparent-button h-auto p-2" onClick={() => setPage(1)}>
                1
              </button>
              <button className="transparent-button h-auto p-2">...</button>
              {pages.slice(last - PAGINGATION_SIZE, last).map((p) => (
                <button key={p} className={`h-auto p-2 ${p === page ? "primary-button" : "transparent-button"}`} onClick={() => setPage(p)}>
                  {p}
                </button>
              ))}
            </>
          ) : (
            <>
              <button className="transparent-button h-auto p-2" onClick={() => setPage(1)}>
                1
              </button>
              <button className="transparent-button h-auto p-2">...</button>
              {pages.slice(page - PAGINGATION_SIZE / 2, page + PAGINGATION_SIZE / 2 - 1).map((p) => (
                <button key={p} className={`h-auto p-2 ${p === page ? "primary-button" : "transparent-button"}`} onClick={() => setPage(p)}>
                  {p}
                </button>
              ))}
              <button className="transparent-button h-auto p-2" disabled={true}>
                ...
              </button>
              <button className="transparent-button h-auto p-2" onClick={() => setPage(last)}>
                {last}
              </button>
            </>
          )
        ) : (
          pages.map((p) => (
            <button key={p} className={`h-auto px-3 py-1 ${p === page ? "primary-button" : "transparent-button"}`} onClick={() => setPage(p)}>
              {p}
            </button>
          ))
        )}
      </div>
      <div className="flex">
        <button className="ml-4 h-auto p-2 transparent-button" onClick={() => (page + 1 > last ? setPage(last) : setPage(page + 1))} disabled={page === last}>
          <MdNavigateNext className="" />
        </button>
        <button className="transparent-button h-auto p-2" onClick={() => setPage(last)} disabled={page === last}>
          <BiLastPage />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
